@import url("https://fonts.cdnfonts.com/css/recoleta");
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h5 {
  font-family: "Domine", serif;
}
p {
  font-family: "Satoshi", sans-serif !important;
}
body {
  overflow-x: hidden !important;
}
