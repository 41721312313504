/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Satoshi Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Regular"), url("Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Italic"), url("Satoshi-Italic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Light";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Light"), url("Satoshi-Light.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Light Italic"),
    url("Satoshi-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Medium"), url("Satoshi-Medium.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Medium Italic"),
    url("Satoshi-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Bold"), url("Satoshi-Bold.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Bold Italic"),
    url("Satoshi-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Black";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Black"), url("Satoshi-Black.woff") format("woff");
}

@font-face {
  font-family: "Satoshi Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Satoshi Black Italic"),
    url("Satoshi-BlackItalic.woff") format("woff");
}
@font-face {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: normal;
  src: local("Recoleta"), url("Recoleta-RegularDEMO.otf") format("otf");
}
